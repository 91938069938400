body {
  height: 100%;
}

.home-parent {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.home-box-container-0 {
	height: auto;
	width: 100%;
	padding: 0 100px;
}

.home-box-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 80px;
  text-align: center;
  overflow-y: auto;
}

.home-box {
  background: #ffffff;
  height: 241px;
  width: 400px;
  min-width: 200px;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  font-size: 28px;
  padding: 0 !important;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-box-1 {
  height: 101px;
}

.home-box-2 {
  height: 140px;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.home-box-button {
	position: absolute !important;
    height: 70px !important;
    width: 70px !important;
    border-radius: 35px !important;
	margin-bottom: 40px !important;
	background: #0C62FB !important;
	color: #FFFFFF !important;
	font-size: 32px !important;
}

.home-box:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.loader-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.segment-box {
  background-color: #f2f2f2;
  height: 50px;
  border-radius: 5px;
  font-size: 16px;
  padding: 8px;
}

.segment-box.active {
  background: #0c62fb;
  color: white;
  transform: scale(1.02);
}

.segment-box:hover {
  background: #0c62fb;
  color: white;
  transform: scale(1.02);
  cursor: pointer;
}

.heading {
  font-size: 18px;
}

.button-class {
  height: inherit !important;
  background-color: #f2f2f2 !important;
  color: black !important;
  border-radius: 5px !important;
  border: 2px solid black !important;
}

.button-class:hover {
  background-color: black !important;
  color: #f2f2f2 !important;
}

.text-left {
  text-align: start;
}
