@font-face {
  font-family: 'FS Albert Pro';
  src: url('./fonts/FSAlbertPro-Bold.eot');
  src: local('FS Albert Pro Bold'), local('FSAlbertPro-Bold'),
    url('./fonts/FSAlbertPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/FSAlbertPro-Bold.woff2') format('woff2'),
    url('./fonts/FSAlbertPro-Bold.woff') format('woff'),
    url('./fonts/FSAlbertPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FS Albert Pro';
  src: url('./fonts/FSAlbertPro-Light.eot');
  src: local('FS Albert Pro Light'), local('FSAlbertPro-Light'),
    url('./fonts/FSAlbertPro-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/FSAlbertPro-Light.woff2') format('woff2'),
    url('./fonts/FSAlbertPro-Light.woff') format('woff'),
    url('./fonts/FSAlbertPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FS Albert Pro';
  src: url('./fonts/FSAlbertPro.eot');
  src: local('FS Albert Pro'), local('FSAlbertPro'),
    url('./fonts/FSAlbertPro.eot?#iefix') format('embedded-opentype'),
    url('./fonts/FSAlbertPro.woff2') format('woff2'),
    url('./fonts/FSAlbertPro.woff') format('woff'),
    url('./fonts/FSAlbertPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.hide {
  display: none !important;
}

.bottom-link {
  color: #0c62fb !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.nbs-btn {
  background: #0c62fb !important;
  width: 160px !important;
  height: 56px !important;
  border-radius: 4px !important;
  font-size: 20px !important;
}

.login-btn {
  background: #0c62fb !important;
  border-radius: 4px !important;
}

.captcha > div > div > div {
    margin: auto !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}